import React from 'react'
import {Link} from 'gatsby';
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import SEO from '../components/App/SEO';
import visuallyAppealingImg from '../assets/images/features/Visually-Appealing.webp'
import { graphql } from 'gatsby';

const NotFoundPage = ({data}) => (
  
  <Layout env={data.site.siteMetadata.env}>
    <Navbar />
    <SEO 
        postTitle='404 | BlueMail App' 
        postDescription='BlueMail 404 page not found'
        postImage="/img/OG/og_image-404.png"
        postURL='404'
        postSEO
    />
    <div className="container pt-120 pb-100">
        <div className="not-found-area">
          <div style={{padding: '0 30px'}}>
            <h1 className="mb-20" style={{fontSize: '6rem', fontWeight: 'normal'}}>Oops!</h1>
            <h1 className="mb-30" style={{fontSize: '2rem', fontWeight: 'normal'}}>Something went wrong.</h1>
            <Link to='/' className='default-btn'>Back to Home Page</Link>
          </div>
          <div>
            <img src={visuallyAppealingImg} alt="visuallyAppealingImg" />
          </div>
        </div>
    </div>
    <Footer />
  </Layout>
)

export default NotFoundPage

export const query = graphql`
query NotFoundPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`